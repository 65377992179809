import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import { DateTimes } from '@/constants/DateTimes';
import { percentageDifference } from '@/formatters/PecentageDifference';
import numberFormatter from '@/formatters/ShortNumberConverter';
import useLineChartData from '@/hooks/charts/useLineChartData';
import usePlatformArtistMetrics from '@/hooks/metrics/usePlatformArtistMetrics';
import { MetricsDataModel } from '@/models/Metrics';

import LineChartComponent from '../charts/LineChart';
import PercentageDifference from '../utility/statuses/PercentageDifference';

const CampaignSpotify = ({ startTime }: { startTime: string }) => {
  const { t } = useTranslation();

  const [totalFollowers, setTotalFollowers] = useState<number>();
  const [differenceFollowers, setDifferenceFollowers] = useState<number>();

  const [totalListeners, setTotalListeners] = useState<number>();
  const [differenceListeners, setDifferenceListeners] = useState<number>();

  const { platformArtistMetrics } = usePlatformArtistMetrics({
    platform: 'spotify',
    fromDate: format(new Date(startTime), 'yyyy-MM-dd'),
    toDate: DateTimes.yesterdayDate,
  });

  const followersDataMap = useMemo(() => {
    if (!platformArtistMetrics) return;
    return [
      {
        name: 'Followers',
        color: 'spotify',
        key: 'value',
        dataMap: new Map(
          platformArtistMetrics?.followers.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.value])
        ),
      },
    ];
  }, [platformArtistMetrics]);

  const listenersDataMap = useMemo(() => {
    if (!platformArtistMetrics) return;
    return [
      {
        name: 'Listeners',
        color: 'spotify',
        key: 'value',
        dataMap: new Map(
          platformArtistMetrics?.listeners.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.value])
        ),
      },
    ];
  }, [platformArtistMetrics]);

  const followersChartData = useLineChartData<MetricsDataModel>({
    startDate: format(new Date(startTime), 'yyyy-MM-dd'),
    data: followersDataMap,
  });

  const listenersChartData = useLineChartData<MetricsDataModel>({
    startDate: format(new Date(startTime), 'yyyy-MM-dd'),
    data: listenersDataMap,
  });

  useEffect(() => {
    if (!platformArtistMetrics) return;

    setTotalFollowers(platformArtistMetrics.followers[platformArtistMetrics.followers.length - 1]?.value);
    setDifferenceFollowers(
      percentageDifference(
        platformArtistMetrics.followers[platformArtistMetrics.followers.length - 1]?.value,
        platformArtistMetrics.followers[0]?.value
      )
    );

    setTotalListeners(platformArtistMetrics.listeners[platformArtistMetrics.listeners.length - 1]?.value);
    setDifferenceListeners(
      percentageDifference(
        platformArtistMetrics.listeners[platformArtistMetrics.listeners.length - 1]?.value,
        platformArtistMetrics.listeners[0]?.value
      )
    );
  }, [platformArtistMetrics]);

  return (
    <>
      <div className="d-flex gap20 mt20 flex-wrap">
        <div className="card-inner flex-w50p w100p-lg-down">
          <h3>{t('CAMPAIGNS-PAGE.SPOTIFY-LISTENERS')}</h3>
          <div className="d-flex gap20 mt10">
            <h3>{numberFormatter(totalListeners ?? 0)}</h3>
            <div className="mt-auto mb-auto">
              <PercentageDifference difference={differenceListeners ?? 0} />
            </div>
          </div>
          <LineChartComponent data={listenersChartData} height={350} />
        </div>
        <div className="card-inner flex-w50p w100p-lg-down">
          <h3>{t('CAMPAIGNS-PAGE.SPOTIFY-FOLLOWERS')}</h3>
          <div className="d-flex gap20 mt10">
            <h3>{numberFormatter(totalFollowers ?? 0)}</h3>
            <div className="mt-auto mb-auto">
              <PercentageDifference difference={differenceFollowers ?? 0} />
            </div>
          </div>
          <LineChartComponent data={followersChartData} height={350} />
        </div>
      </div>
    </>
  );
};

export default CampaignSpotify;
