import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Tooltip } from '@mui/material';

import numberFormatter from '@/formatters/ShortNumberConverter';
import useMetaAdReport from '@/hooks/meta/useMetaAdReport';

import Loading from '../utility/Loading';

const CampaignSocialMediaMetaReport = ({ campaignId }: { campaignId: string }) => {
  const { t } = useTranslation();

  const [isReport, setIsReport] = useState<boolean>(false);

  const { metaAdReport, metaAdReportIsLoading } = useMetaAdReport({ campaignId });

  useEffect(() => {
    if (!metaAdReport) return;

    setIsReport(true);
  }, [metaAdReport]);

  const overviewData = [
    {
      name: 'Spent',
      value: metaAdReport?.spend,
      icon: 'pound',
      tooltipTitle: 'Spent',
      tooltipInfo: 'The total amount of money you have paid for the ad campaign, not including any booking fees',
    },
    {
      name: 'Reach',
      value: numberFormatter(Number(metaAdReport?.reach)),
      icon: 'person',
      tooltipTitle: 'Reach',
      tooltipInfo: 'The number of fans who saw your ad at least once',
    },
    {
      name: 'Impressions',
      value: numberFormatter(Number(metaAdReport?.impressions)),
      icon: 'eye',
      tooltipTitle: 'Impressions',
      tooltipInfo: 'The number of times your ad was viewed',
    },
    {
      name: 'CPM',
      value: numberFormatter(Number(metaAdReport?.cpm), 2),
      icon: 'pound',
      tooltipTitle: 'Cost Per Mille',
      tooltipInfo: 'The cost per 1000 impressions',
    },
    {
      name: 'Clicks',
      value: metaAdReport?.clicks,
      icon: 'cursor',
      tooltipTitle: 'Clicks',
      tooltipInfo: 'The number of clicks on your ad',
    },
    {
      name: 'CPC',
      value: numberFormatter(Number(metaAdReport?.cpc), 2),
      icon: 'pound',
      tooltipTitle: 'Cost Per Click',
      tooltipInfo: 'The average cost per click on your ad',
    },
    {
      name: 'CTR',
      value: numberFormatter(Number(metaAdReport?.ctr), 2),
      icon: 'pie',
      tooltipTitle: 'Click Through Rate',
      tooltipInfo: 'The % of impressions where a click occurred (impressions/click)',
    },
    {
      name: 'Frequency',
      value: numberFormatter(Number(metaAdReport?.frequency), 2),
      icon: 'bar-chart',
      tooltipTitle: 'Frequency',
      tooltipInfo: 'The average number of times a fan saw this ad',
    },
  ];

  return (
    <>
      {isReport && !metaAdReportIsLoading && (
        <div className="d-flex gap8 flex-wrap">
          {overviewData.map((item, index: number) => (
            <div className="card-inner flex-grow d-flex campaign-report-item pos-rel" key={index}>
              <Tooltip
                title={
                  <div>
                    <p className="mb4 small">{t(item.tooltipTitle)}</p>
                    <p className="mb4 small pt0 text-faded">{item.tooltipInfo}</p>
                  </div>
                }
                placement="top"
              >
                <Icon className="material-symbols-outlined pos-abs r0 t0 mt4 mr4 text-faded">info</Icon>
              </Tooltip>
              <img className="ml-10" src={`/images/icons/${item.icon}.svg`} alt="" />
              <div className="pl8">
                <p className="fw-bold">{item.value}</p>
                <p className="text-faded small pt0">{item.name}</p>
              </div>
            </div>
          ))}
        </div>
      )}
      {!isReport && !metaAdReportIsLoading && (
        <div className="card-inner w100p">
          <p>It's too early in your campaign to gather performance metrics, come back later to see your growth 📈</p>
        </div>
      )}
      {metaAdReportIsLoading && <Loading size="small" />}
    </>
  );
};

export default CampaignSocialMediaMetaReport;
