import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import useLineChartData from '@/hooks/charts/useLineChartData';
import usePlatformArtistMetrics from '@/hooks/metrics/usePlatformArtistMetrics';
import { MetricsDataModel } from '@/models/Metrics';

import { DateTimes } from '../../constants/DateTimes';
import { percentageDifference } from '../../formatters/PecentageDifference';
import numberFormatter from '../../formatters/ShortNumberConverter';
import LineChartComponent from '../charts/LineChart';
import PercentageDifference from '../utility/statuses/PercentageDifference';

const CampaignYoutube = ({ startTime }: { startTime: string }) => {
  const { t } = useTranslation();

  const [totalSubscribers, setTotalSubscribers] = useState<number>();
  const [totalViewers, setTotalViewers] = useState<number>();
  const [differenceSubscribers, setDifferenceSubscribers] = useState<number>();
  const [differenceViewers, setDifferenceViewers] = useState<number>();

  const { platformArtistMetrics } = usePlatformArtistMetrics({
    platform: 'youtube_channel',
    fromDate: format(new Date(startTime), 'yyyy-MM-dd'),
    toDate: DateTimes.yesterdayDate,
  });

  const subscribersDataMap = useMemo(() => {
    if (!platformArtistMetrics) return;
    return [
      {
        name: 'Subscribers',
        color: 'facebook',
        key: 'value',
        dataMap: new Map(
          platformArtistMetrics?.subscribers.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.value])
        ),
      },
    ];
  }, [platformArtistMetrics]);

  const viewsDataMap = useMemo(() => {
    if (!platformArtistMetrics) return;
    return [
      {
        name: 'Views',
        color: 'facebook',
        key: 'value',
        dataMap: new Map(
          platformArtistMetrics?.views.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.value])
        ),
      },
    ];
  }, [platformArtistMetrics]);

  const subscribersChartData = useLineChartData<MetricsDataModel>({
    startDate: format(new Date(startTime), 'yyyy-MM-dd'),
    data: subscribersDataMap,
  });

  const viewsChartData = useLineChartData<MetricsDataModel>({
    startDate: format(new Date(startTime), 'yyyy-MM-dd'),
    data: viewsDataMap,
  });

  useEffect(() => {
    if (!platformArtistMetrics) return;

    setTotalSubscribers(platformArtistMetrics.subscribers[platformArtistMetrics.subscribers.length - 1]?.value);
    setDifferenceSubscribers(
      percentageDifference(
        platformArtistMetrics.subscribers[platformArtistMetrics.subscribers.length - 1]?.value,
        platformArtistMetrics.subscribers[0]?.value
      )
    );

    setTotalViewers(platformArtistMetrics.views[platformArtistMetrics.views.length - 1]?.value);
    setDifferenceViewers(
      percentageDifference(
        platformArtistMetrics.views[platformArtistMetrics.views.length - 1]?.value,
        platformArtistMetrics.views[0]?.value
      )
    );
  }, [platformArtistMetrics]);

  return (
    <>
      <div className="d-flex gap20 mt20 flex-wrap">
        <div className="card-inner flex-w50p w100p-lg-down">
          <h3>{t('CAMPAIGNS-PAGE.YOUTUBE-VIEWS')}</h3>
          <div className="d-flex gap20 mt10">
            <h3>{numberFormatter(totalViewers ?? 0)}</h3>
            <div className="mt-auto mb-auto">
              <PercentageDifference difference={differenceViewers ?? 0} />
            </div>
          </div>
          <LineChartComponent data={viewsChartData} height={350} />
        </div>
        <div className="card-inner flex-w50p w100p-lg-down">
          <h3>{t('CAMPAIGNS-PAGE.YOUTUBE-SUBSCRIBERS')}</h3>
          <div className="d-flex gap20 mt10">
            <h3>{numberFormatter(totalSubscribers ?? 0)}</h3>
            <div className="mt-auto mb-auto">
              <PercentageDifference difference={differenceSubscribers ?? 0} />
            </div>
          </div>
          <LineChartComponent data={subscribersChartData} height={350} />
        </div>
      </div>
    </>
  );
};

export default CampaignYoutube;
