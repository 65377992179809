import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import useLineChartData from '@/hooks/charts/useLineChartData';
import usePlatformArtistMetrics from '@/hooks/metrics/usePlatformArtistMetrics';
import { MetricsDataModel } from '@/models/Metrics';

import { DateTimes } from '../../constants/DateTimes';
import { percentageDifference } from '../../formatters/PecentageDifference';
import numberFormatter from '../../formatters/ShortNumberConverter';
import LineChartComponent from '../charts/LineChart';
import PercentageDifference from '../utility/statuses/PercentageDifference';

const CampaignFacebook = ({ startTime }: { startTime: string }) => {
  const { t } = useTranslation();

  const [totalLikes, setTotalLikes] = useState<number>();
  const [differenceLikes, setDifferenceLikes] = useState<number>();

  const [totalTalks, setTotalTalks] = useState<number>();
  const [differenceTalks, setDifferenceTalks] = useState<number>();

  const { platformArtistMetrics } = usePlatformArtistMetrics({
    platform: 'facebook',
    fromDate: format(new Date(startTime), 'yyyy-MM-dd'),
    toDate: DateTimes.yesterdayDate,
  });

  const likesDataMap = useMemo(() => {
    if (!platformArtistMetrics) return;
    return [
      {
        name: 'Likes',
        color: 'facebook',
        key: 'value',
        dataMap: new Map(
          platformArtistMetrics?.likes.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.value])
        ),
      },
    ];
  }, [platformArtistMetrics]);

  const talksDataMap = useMemo(() => {
    if (!platformArtistMetrics) return;
    return [
      {
        name: 'Talks',
        color: 'facebook',
        key: 'value',
        dataMap: new Map(
          platformArtistMetrics?.talks.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.value])
        ),
      },
    ];
  }, [platformArtistMetrics]);

  const likesChartData = useLineChartData<MetricsDataModel>({
    startDate: format(new Date(startTime), 'yyyy-MM-dd'),
    data: likesDataMap,
  });

  const talksChartData = useLineChartData<MetricsDataModel>({
    startDate: format(new Date(startTime), 'yyyy-MM-dd'),
    data: talksDataMap,
  });

  useEffect(() => {
    if (!platformArtistMetrics) return;

    setTotalLikes(platformArtistMetrics.likes[platformArtistMetrics.likes.length - 1]?.value);
    setDifferenceLikes(
      percentageDifference(
        platformArtistMetrics.likes[platformArtistMetrics.likes.length - 1]?.value,
        platformArtistMetrics.likes[0]?.value
      )
    );

    setTotalTalks(platformArtistMetrics.talks[platformArtistMetrics.talks.length - 1]?.value);
    setDifferenceTalks(
      percentageDifference(
        platformArtistMetrics.talks[platformArtistMetrics.talks.length - 1]?.value,
        platformArtistMetrics.talks[0]?.value
      )
    );
  }, [platformArtistMetrics]);

  return (
    <>
      <div className="d-flex gap20 mt20 flex-wrap">
        <div className="card-inner flex-w50p w100p-lg-down">
          <h3>{t('CAMPAIGNS-PAGE.FACEBOOK-LIKES')}</h3>
          <div className="d-flex gap20 mt10">
            <h3>{numberFormatter(totalLikes ?? 0)}</h3>
            <div className="mt-auto mb-auto">
              <PercentageDifference difference={differenceLikes ?? 0} />
            </div>
          </div>
          <LineChartComponent data={likesChartData} height={350} />
        </div>
        <div className="card-inner flex-w50p w100p-lg-down">
          <h3>{t('CAMPAIGNS-PAGE.FACEBOOK-TALKS')}</h3>
          <div className="d-flex gap20 mt10">
            <h3>{numberFormatter(totalTalks ?? 0)}</h3>
            <div className="mt-auto mb-auto">
              <PercentageDifference difference={differenceTalks ?? 0} />
            </div>
          </div>
          <LineChartComponent data={talksChartData} height={350} />
        </div>
      </div>
    </>
  );
};

export default CampaignFacebook;
